import * as Yup from "yup";

export const AddItemlValidationSchema = Yup.object({
  title: Yup.string().required("This is required").min(3),
  description: Yup.string().required("This is required"),
  code: Yup.string()
    .required("This is required")
    .matches(/^[a-zA-Z0-9]*$/, "No special characters or spaces allowed"),
  brand: Yup.string().required("This is required"),
  ingredients: Yup.string().required("This is required"),
  moreInformation: Yup.string().required("This is required"),
  usageInstructions: Yup.string().required("This is required"),
  imageUrl: Yup.string().required("This is required"),
});
