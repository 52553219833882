import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import { AddItemlValidationSchema } from "./AddItemValidationSchema";
import { db } from "./firebase";
import { BrandsOptions, ImageUploadField, InputField } from "./components";
import { useAuth } from "../hooks/useAuth";
import { Login } from "./login";

export function AddItemForm() {
  const user = useAuth();

  if (user === null) {
    return (
      <div className="App">
        <Login />
      </div>
    );
  }
  return <AddItemFormComponent />;
}

export function AddItemFormComponent() {
  const navigate = useNavigate();
  const findProductByTitle = async (itemId: string) => {
    const docRef = doc(db, "items", itemId);
    const docSnap = await getDoc(docRef);
    return docSnap.exists();
  };

  return (
    <div
      style={{
        padding: "80px",
      }}
    >
      <Formik
        initialValues={{
          title: "",
          description: "",
          code: "",
          brand: null,
          ingredients: "",
          moreInformation: "",
          usageInstructions: "",
          imageUrl: "",
        }}
        validationSchema={AddItemlValidationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setSubmitting(true);

            const exists = await findProductByTitle(values.code);
            if (exists) {
              throw new Error("This Product ID is already in use");
            }

            await setDoc(doc(db, "items", values.code), {
              title: values.title,
              description: values.description,
              code: values.code,
              brand: values.brand,
              ingredients: values.ingredients,
              moreInformation: values.moreInformation,
              usageInstructions: values.usageInstructions,
              imageUrl: values.imageUrl,
            });

            navigate("/admin");
          } catch (error) {
            window.alert(error);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ values, isValid, dirty, isSubmitting, setFieldValue }) => (
          <Form>
            <InputField label={"Product Title"} name={"title"}></InputField>
            <InputField
              label={"Product Description"}
              name={"description"}
            ></InputField>
            <InputField label={"Product Code"} name={"code"}></InputField>
            <InputField
              label={"Product Ingredients"}
              name={"ingredients"}
            ></InputField>
            <InputField
              label={"More Information"}
              name={"moreInformation"}
            ></InputField>
            <InputField
              label={"Usage Instructions"}
              name={"usageInstructions"}
            ></InputField>
            <div style={{ margin: "16px 0px" }}>
              <p className="Tx16M24">Product Brand</p>
              <Field
                as="select"
                name="brand"
                style={{ width: "100%", padding: "16px 24px" }}
              >
                <BrandsOptions></BrandsOptions>
              </Field>
            </div>

            <p className="Tx16M24">Product Image</p>
            <ImageUploadField name={"imageUrl"} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                className="button"
                type="submit"
                style={{ marginTop: "24px" }}
                disabled={!isValid || !dirty || isSubmitting}
              >
                Add Item
              </button>
              <Link to={"/admin"}>
                <button className="button2" style={{ marginTop: "24px" }}>
                  Cancel
                </button>
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
