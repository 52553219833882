import { doc, getDoc, deleteDoc, updateDoc } from "firebase/firestore";
import { Formik, Form } from "formik";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Item from "../item/ItemContainer";
import { AddItemlValidationSchema } from "./AddItemValidationSchema";
import { InputField, ImageUploadField } from "./components";
import { db } from "./firebase";
import { Brand } from "./admin";
import { Login } from "./login";
import { useAuth } from "../hooks/useAuth";

export function EditBrandForm() {
  const user = useAuth();

  if (user === null) {
    return (
      <div className="App">
        <Login />
      </div>
    );
  }
  return <EditBrandFormComponent />;
}

export function EditBrandFormComponent() {
  const [initialValues, setInitialValues] = useState<Brand>({
    title: "",
    code: "",
    imageUrl: "",
  });
  const [itemId, setItemId] = useState<string>("");
  const navigate = useNavigate();
  const findProductByTitle = async (itemId: string) => {
    const docRef = doc(db, "brands", itemId);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data() as Brand;

    if (docSnap.exists()) {
      console.log("all set");
      return data;
    } else {
      return {
        title: "",
        code: "",
        imageUrl: "",
      };
    }
  };

  useEffect(() => {
    const { pathname } = window.location;
    const itemId = decodeURIComponent(pathname?.split("/").pop() || "");
    setItemId(itemId);
    const foundProduct = findProductByTitle(itemId);
    if (foundProduct) {
      foundProduct.then((x) => setInitialValues(x));
    }
  }, []);

  return (
    <div
      style={{
        padding: "80px",
      }}
    >
      <button
        className="button3"
        onClick={async (e) => {
          await deleteDoc(doc(db, "brands", itemId)).then((e) =>
            navigate("/admin")
          );
        }}
      >
        Delete
      </button>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await updateDoc(doc(db, "brands", itemId), {
            title: values.title,
            code: values.code,
            imageUrl: values.imageUrl,
          })
            .then((e) => navigate("/admin"))
            .catch((error) => window.alert(error));
        }}
      >
        {({ values, isValid, dirty, isSubmitting, setFieldValue }) => (
          <Form>
            <InputField label={"Product Title"} name={"title"}></InputField>
            <InputField
              readOnly
              disabled
              label={"Product Code"}
              name={"code"}
            ></InputField>
            <div style={{ margin: "16px 0px" }}>
              <ImageUploadField name={"imageUrl"} />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                className="button"
                type="submit"
                style={{ marginTop: "24px" }}
                disabled={!isValid || !dirty || isSubmitting}
              >
                Update Item
              </button>
              <Link to={"/admin"}>
                <button className="button2" style={{ marginTop: "24px" }}>
                  Cancel
                </button>
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
